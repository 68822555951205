<template>
  <div class="widgetContainer__body">
    <div class="widgetContainer__header-fixed">
      <div class="headerExport">
      </div>
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-container fluid>
           <!--  <v-toolbar outlined flat v-if="!isNoInvoiceCreated">
              <v-tabs v-model="selectedStatus" @change="onSelectselectedStatus">
                <v-tab class="text-capitalize">{{ $t('all') }}</v-tab>
                <v-tab class="text-capitalize">{{ $t('paid') }}</v-tab>
                <v-tab class="text-capitalize">{{ $t('unpaid') }}</v-tab>
              </v-tabs>
            </v-toolbar> -->

            <v-data-table
              :headers="tableHeaders"
              :items="invoicesDataList"
              class="elevation-1 v-table-middle-align"
              :single-select="false"
              show-select
              v-model="selectedData"
              disable-pagination
              :hide-default-footer="true"
              :loading="isInvoiceDataLoading"
            >
              <template v-slot:top>
                <div class="d-block d-sm-flex pa-4">
                  <div class="mr-auto">
                    <p class="header-1 mb-0 pt-2">{{ $t('invoices') }}</p>
                  </div>
                  <div class="d-sm-flex justify-end">
                    <div class="d-inline d-sm-flex">
                      <div class="mb-sm-0 mb-2">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" outlined large height="40" class="text-capitalize text-left" color="primary" block>
                              {{ $t('status') + (selectedStatus ? ': ' + selectedStatus : '') }}
                              <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                          </template>
                          <v-card width="300">
                            <v-card-text>
                              <v-list dense class="py-0">
                                <v-list-item-group
                                  v-model="selectedStatus"
                                  color="primary"
                                  @change="fetchInvoices"
                                >
                                  <v-list-item :value="''">
                                    <v-list-item-content>
                                      <v-list-item-title>NONE</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    v-for="status in invoiceStatusList"
                                    :key="status"
                                    :value="status"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title v-text="status" class="text-capitalize"></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </div>
                      <div class="mx-0 mx-sm-2">
                        <v-text-field
                          :label="$t('search_invoice')"
                          prepend-inner-icon="mdi-magnify"
                          clearable
                          hide-details
                          flat
                          outlined
                          solo
                          dense
                          v-model="searchText"
                          height="42"
                          full-width
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="mt-2 mt-sm-0">
                      <v-btn class="text-white btn-primary text-capitalize" height="42px" text @click="onToggleInvoiceForm" block>
                        {{ $t('create_invoice') }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-divider/>
              </template>
              <template v-slot:item.issued_at="{ item }">
                {{ dateMDYFormat(item.issued_at) }}
              </template>
              <template v-slot:[`item.document_number`]="{item}">
                <a href="#" @click.prevent="$router.push(`/accounting/invoices/${item.id}`)">
                  <span>{{ item.document_number }}</span>
                </a>
              </template>
              <template v-slot:item.customer="{ item }">
                <span>{{ JSON.parse(item.contact_ref).name }}</span>
              </template>
              <template v-slot:item.due_at="{ item }">
                {{ dateMDYFormat(item.due_at) }}
              </template>
              <template v-slot:item.status="{ item }">
                <span 
                  :class="{
                    'status-paid': item.status === 'paid',
                    'status-overdue': item.status === 'overdue',
                    'status-partial': item.status === 'partially paid',
                    'status-open': item.status === 'open',
                    'status-draft': item.status === 'draft',
                  }"
                  class="text-uppercase"
                >{{ item.status }}</span>
                <p v-if="item.status==='paid'" class="grey--text">
                  {{ $t('on') }} {{ dateMDYFormat(item.paidDate) }}
                </p>
                <p v-if="item.partialAmount" class="grey--text">
                  {{ item.status === 'Partially Paid' ? $t('paid') : $t('partially_paid')+' ' }} ${{ currencyDollarFormat(item.partialAmount) }}
                </p>
              </template>
              <template v-slot:item.actions="{ item }">
                <!-- <v-btn small class="blue--text mr-1" text outlined>
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn> -->
                <v-menu offset-y left min-width="180">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" primary v-on="on" color="primary" text outlined small width="28">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link v-if="item.status === 'partially paid'" @click="onTogglePaymentModal(item)">
                      <v-list-item-title class="app-link-color">{{ $t('receive') }} {{ $t('payment') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$router.push(`/accounting/invoices/${item.id}`)">
                      <v-list-item-title>{{ $t('view') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link v-if="item.status !== 'paid'" @click="onEditForm(item)">
                      <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item link>
                      <v-list-item-title>{{ $t('duplicate') }}</v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item link v-if="item.status !== 'paid'">
                      <v-list-item-title>{{ $t('send') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link v-if="item.status !== 'paid'">
                      <v-list-item-title>{{ $t('send_reminder') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>{{ $t('print') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title>{{ $t('download') }}</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item link @click="onToggleDeleteInvoice(item)">
                      <v-list-item-title class="red--text">{{ $t('delete') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:no-results>
                <v-card width="300" flat class="mx-auto text-center my-16">
                  <v-icon size="52" color="#0171a1">mdi-magnify</v-icon>
                  <v-card-text>
                    <span class="black--text text-h5">{{ $t('invoice_no_matching_result') }}</span>
                    <p class="font-weight-bold">{{ $t('invoice_no_matching_result_description') }}</p>
                    <v-btn text outlined color="#0171a1">
                      <span class="text-capitalize">{{ $t('try_different_search') }}</span>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </template>
              <template v-slot:foot>
                <tfoot>
                  <tr>
                    <td colspan="9" class="text-right">
                      <div class="d-flex justify-md-end justify-lg-end justify-center">
                        <!-- <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{pagination.current_page || 0}} - {{ pagination.total_pages || 0 }} {{ $t('of') }} {{ (pagination.total || 0).toLocaleString() }} <span class="d-sm-inline d-none">{{ $t('records') }}</span></h6> -->
                        <h6 class="text-subtitle-1 mt-2 labelcolor--text">{{ (pagination.total || 0).toLocaleString() }} <span class="">{{ $t('records') }}</span></h6>
                        <v-spacer/>
                        <div class="select-page-limit">
                          <v-select
                            v-model="pageLimit"
                            :items="[10, 20, 25]"
                            label="Per page"
                            hide-details
                            dense
                            flat
                            solo
                            @change="onChangePageLimit"
                          ></v-select>
                        </div>
                        <pagination
                          :total="pagination.total_pages || 1"
                          :current-page="currentPage"
                          :total-visible="10"
                          @pageSelected="onPaginationClick"
                        >
                        </pagination>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-data-table>
            <v-snackbar
              timeout="2000"
              vertical
              :color="snackbarOption.color"
              v-model="showSnackbar"
              bottom
            >
              <v-icon v-if="snackbarOption.icon">{{ snackbarOption.icon }}</v-icon> {{ snackbarOption.message }}
            </v-snackbar>
          </v-card>
          <invoice-form :open="showFormDialog" :is-edit-mode="isEditMode" :form-values="selectedInvoiceData" @toggle="onToggleInvoiceForm"></invoice-form>
          <invoice-delete :open="showDeleteInvoiceModal" :form-data="formDataToDelete" @toggle="onToggleDeleteInvoice"></invoice-delete>
          <receive-payment-form :open="showPaymentModal" :invoice="selectedInvoiceData || {}" :is-edit-mode="false" @toggle="onTogglePaymentModal"></receive-payment-form>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import InvoiceForm from '../components/InvoiceForm.vue';
import InvoiceDelete from '../components/InvoiceDeleteModal.vue';
import ReceivePaymentForm from '../components/ReceivePaymentForm.vue';
import { apiErrorMessage, debounce } from '@/utils/general';
import moment from 'moment';
import Pagination from '../components/Pagination.vue';

import { mapActions } from 'vuex';

export default {
  components: {
    InvoiceForm,
    InvoiceDelete,
    ReceivePaymentForm,
    Pagination,
  },
  data() {
    return {
      selectedInvoiceData: null,
      isEditMode: false,
      showFormDialog: false,
      showDeleteInvoiceModal: false,
      showPaymentModal: false,
      formDataToDelete: {},
      selectedData: [],
      filterBy: null,
      searchText: '',
      selectedStatus: null,
      isNoInvoiceCreated: false, // Get the value from the server
      invoicesData: [],
      isInvoiceDataLoading: false,
      showSnackbar: false,
      snackbarOption: {
        icon: '',
        message: '',
        color: ''
      },
      isFormEditMode: false,
      selectedInvoiceData: null,
      pageLimit: 10,
      currentPage: 1,
      invoiceStatusList: [
        'cancelled',
        'draft',
        'paid',
        'partial',
        'sent',
        'viewed',
      ],
    };
  },

  computed: {
    tableHeaders(){
      return [{
          text: this.$t('invoice_date'),
          align: "center",
          value: "issued_at",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: this.$t('invoice_no'),
          align: "center",
          value: "document_number",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: this.$t('customer'),
          align: "left",
          value: "customer",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: this.$t('order_number'),
          align: "left",
          value: "order_number",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: this.$t('due_date'),
          align: "right",
          value: "due_at",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: this.$t('amount'),
          align: "right",
          value: "amount_formatted",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: this.$t('status'),
          align: "right",
          value: "status",
          class: "text-uppercase th--text font-weight-bold"
        }, {
          text: "",
          align: "center",
          value: "actions",
          class: "text-uppercase th--text font-weight-bold"
        }];
    },

    invoicesDataList: {
      get() {
        return this.invoicesData?.data || [];
      }
    },

    pagination: {
      get() {
        return this.invoicesData.data ? this.invoicesData.meta.pagination : {};
      }
    },
  },

  created() {
    // this.tempInvoiceData = JSON.parse(JSON.stringify(this.invoicesData));
    this.fetchInvoices();
  },

  watch: {
    searchText: debounce(function() {
      this.fetchInvoices();
    }, 600)
  },

  methods: {
    //
    ...mapActions('accounting', ['getInvoicesData']),

    async fetchInvoices() {
      if(this.isInvoiceDataLoading) {
        return;
      }
      try {
        this.isInvoiceDataLoading = true;
        const filters = {
          limit: this.pageLimit,
          page: this.currentPage,
          search: this.searchText || '',
          status: this.selectedStatus || '',
        };
        const { data } = await this.getInvoicesData(filters);
        this.invoicesData = data || [];
      } catch(error) {
        apiErrorMessage(error);
      } finally {
        this.isInvoiceDataLoading = false;
      }
    },

    onPaginationClick(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchInvoices();
    },

    onChangePageLimit() {
      this.currentPage = 1;
      this.fetchInvoices();
    },

    onEditForm(data) {
      this.isEditMode = true;
      this.selectedInvoiceData = JSON.parse(JSON.stringify(data));
      this.showFormDialog = true;
    },

    onToggleInvoiceForm(options = {}) {
      this.showFormDialog = !this.showFormDialog;
      this.isEditMode = false;
      if(options.created || options.updated) {
        this.snackbarOption = {
					icon: 'mdi-check',
					color: 'success',
					message: options.message,
				};
				this.showSnackbar = true;
        this.fetchInvoices();
      }
    },

    onTogglePaymentModal(data = null) {
      this.showPaymentModal = !this.showPaymentModal;
      if(!this.showPaymentModal) {
        this.selectedInvoiceData = {};
      } else {
        this.selectedInvoiceData = JSON.parse(JSON.stringify(data));
      }
    },

    onToggleDeleteInvoice(data = {}, options = {}) {
      this.formDataToDelete = JSON.parse(JSON.stringify(data));
      this.showDeleteInvoiceModal = !this.showDeleteInvoiceModal;
      if(options.deleted) {
        this.snackbarOption = {
					icon: 'mdi-delete',
					color: 'red',
					message: options.message || 'Deleted',
				};
        this.fetchInvoices();
        this.showSnackbar = true;
      }
    },

    currencyDollarFormat(number = 0) {
      return new Intl.NumberFormat('en-US').format(number);
    },

    dateMDYFormat(dateString) {
      
      return moment(dateString, 'YYYY-MM-DD').format('MMM DD, YYYY');
    },

    /* onSelectselectedStatus() {
      const statusType = this.selectedStatus;
      //@TODO
      // Fetch from API here...
      this.invoicesData = this.tempInvoiceData.filter( record => {
        return statusType === 1 ? ['Paid', 'Partially Paid'].includes(record.status) : statusType === 2 ? !['Paid', 'Partially Paid'].includes(record.status) : true;
      });
    }, */
  }

};
</script>
<style lang="scss" scoped>
  $btn-active-color: #0171a1;
  $app-text-color: #0171a1;  

  .app-link-color {
    color: $app-text-color;
  }

  .vue-app {
    background: none;
  }
  .btn-primary {
    background-color: $btn-active-color !important;
    color: #ffffff !important;
  }
  .status-paid {
    color: #49af41;
  }

  .status-overdue {
    color: #f93131;
  }

  .status-partial {
    color: #2dc48d;
  }

  .status-open {
    color: #0171a1;
  }

  .status-draft {
    color: #81a0b4;
  }
  .v-list-item {
    min-height: 36px;
  }
  .select-page-limit {
    width: 80px;
  }
</style>
<style lang="scss">
  .v-table-middle-align {
    td, th {
      vertical-align: middle !important;
    }
  }
</style>